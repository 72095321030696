import { Controller } from "@hotwired/stimulus"
import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import ProgressBar from "@uppy/progress-bar"
import ThumbnailGenerator from "@uppy/thumbnail-generator"
import Dashboard from "@uppy/dashboard"
import DropTarget from '@uppy/drop-target'

export default class extends Controller {
  static targets = [ "bulkUploadForm" ]

  connect() {
    $(this.element).on("cocoon:after-insert", this.handleAfterInsert)

    this.uppyBulk = new Uppy({ debug: true, autoProceed: true, restrictions: { allowedFileTypes: ["image/*"] }})
    this.uppyBulk.use(Dashboard, {
      proudlyDisplayPoweredByUppy: false,
      trigger: '#bulkUpload',
    })
    this.uppyBulk.use(DropTarget, { target: document.body })
    this.uppyBulk.on("upload", () => $('#uploadNotice').css('display', 'inline-block'))
    this.uppyBulk.on("upload-success", (file, response) => {
      console.log('[  upload-success  ]', file, response);
      let uuid = Math.floor(Math.random() * 100000000);
      this.bulkUploadFormTarget.insertAdjacentHTML('beforeend', `<input type="hidden" name="artist[pictures_attributes][${uuid}][image]" value='${JSON.stringify(response.body)}'>`)
    })
    this.uppyBulk.on("complete", (result) => {
      console.log('upload complete', result)
      $('#uploadNotice').css('display', 'none')
    })
    this.uppyBulk.on("dashboard:modal-closed", () => {
      this.bulkUploadFormTarget.submit()
    })
    this.uppyBulk.use(XHRUpload, { endpoint: '/pictures/upload'});


    this.uppy = new Uppy({ debug: true, autoProceed: true, restrictions: { allowedFileTypes: ["image/*"] }})
    this.uppy.use(ThumbnailGenerator, { thumbnailWidth: 500 })
    this.uppy.use(ProgressBar, { fixed: true });
    this.uppy.use(XHRUpload, { endpoint: '/pictures/upload'});

    this.uppy.on("upload", () => {
      $('#uploadNotice').css('display', 'inline-block')
    });

    this.uppy.on("complete", () => {
      setTimeout(() => {
        const uploadingItems = $(this.element).find("[data-uploading]");
        console.log('result', uploadingItems);
        if (uploadingItems.length <= 0) {
          $('#uploadNotice').css('display', 'none')
        }
      });
    });

    this.uppy.on("thumbnail:generated", (file, preview) => {
      const item = $(`[data-file-id='${file.id}']`);
      const imageEl = item.find("img").get(0);
      imageEl.src = preview;
      imageEl.style.width = null
    });

    this.uppy.on("upload-success", (file, response) => {
      const item = $(`[data-file-id='${file.id}']`);
      const dataEl = item.find("input[type=hidden]").get(0);
      item.removeAttr('data-uploading');
      dataEl.value = JSON.stringify(response.body);
    });
  }

  handleAfterInsert = (e, insertedItem) => {
    const container = insertedItem.get(0);
    const imageInput = insertedItem.find("input[type=file]").get(0);

    imageInput.addEventListener('change', (event) => {
      const file = event.target.files[0];
      const id = this.uppy.addFile({
        source: 'file input',
        name: file.name,
        type: file.type,
        data: file
      });
      container.dataset.fileId = id;
      container.dataset.uploading = true;
    })

    imageInput.click();
  }

  bulkUpload() {
  }

  disconnect() {
    $(this.element).off("cocoon:after-insert", this.handleAfterInsert)
  }
}
