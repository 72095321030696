<template>
  <div
    class="d-flex flex-row p-3 bg-white sticky-bottom justify-content-between align-items-start border shadow-sm"
  >
    <h5>
      Main Board Selected: {{ count }}
    </h5>
    <div class="d-flex">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["count"],
};
</script>
