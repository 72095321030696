<template>
  <div class="flex-wrap d-flex align-content-center small">
    <a target="_blank" v-for="(bookings, date) in artist.booking_dates" class="mx-2 btn-sm btn" :class="bookings[0].status === 'tentative' ? 'btn-outline-primary' : 'btn-outline-success'" :key="date" :href="bookingFilterLink(date)">
      {{ new Date(date) | humanizeDate }} | <span>{{ bookings[0].status | titleize }}</span>
    </a>
  </div>
</template>

<script>
export default {
  props: ["artist"],
  methods: {
    bookingFilterLink(date) {
      return `/bookings?q[booking_dates_artist_id_eq]=${this.artist.id}&q[booking_dates_on_date_eq]=${date}&q[booking_dates_status_eq]=tentative`;
    },
  },
};
</script>

<style>

</style>
