import { Application } from "@hotwired/stimulus"
import { registerControllers } from 'stimulus-vite-helpers'

// Inertia + Vue
import Vue from "vue/dist/vue.esm.js";
import { createInertiaApp } from '@inertiajs/vue2'
import VueLoaders from "vue-loaders";
import vSelect from "vue-select";
import format from "date-fns/format";
import fromUnixTime from "date-fns/fromUnixTime";
import parseISO from "date-fns/parseISO";
import axios from "axios";
import last from "lodash/last";
import startCase from "lodash/startCase";

// Stimulus
const application = Application.start()

const controllers = import.meta.glob("../controllers/*_controller.js", { eager: true })
registerControllers(application, controllers)

// Inertia Vue
Vue.config.devtools = true
Vue.use(VueLoaders);
Vue.component("v-select", vSelect);

// Register all ../components/common globally
const components = import.meta.glob("../Components/Common/**/*.vue", { eager: true })
Object.entries(components).forEach(([path, component]) => {
  const name = last(path.split("/")).match(/\w+/)[0];
  Vue.component(name, component.default);
});

Vue.filter("unixToDate", (value) => fromUnixTime(value));
Vue.filter("humanizeDate", (value) => format(value, "do MMM yyy"));
Vue.filter("toDate", (value) => parseISO(value));
Vue.filter("titleize", (value) => startCase(value));

axios.defaults.xsrfHeaderName = "X-CSRF-Token";

const inertiaInit = () => {
  const app = document.querySelector("#app");
  if (app) {
    createInertiaApp({
      progress: false,
      resolve: name => {
        const pages = import.meta.glob("../Pages/**/*.vue", { eager: true })
        return pages[`../Pages/${name}.vue`]
      },
      setup({ el, App, props, plugin }) {
        Vue.use(plugin)

        new Vue({
          render: h => h(App, props),
        }).$mount(el)
      },
    })
  }
}

document.addEventListener("DOMContentLoaded", () => inertiaInit())