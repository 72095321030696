<template>
  <a :href="artistScheduleLink" target="_blank" class="ms-2">
    <i
      class="bi-calendar-fill cursor-pointer btn p-2"
    ></i>
  </a>
</template>
<script>
export default {
  props: ["artist"],
  computed: {
    artistScheduleLink() {
      return `/artists/${this.artist.id}/schedule`;
    }
  }
};
</script>
