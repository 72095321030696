<template>
  <span class="d-flex">
    <span>Main Board Availability →</span>
    <span v-if="dates.length > 0" class="mx-1">{{ dates.join(', ') }}</span>
  </span>
</template>

<script>
export default {
  props: {
    dates: {
      type: Array,
      default: []
    }
  }
};
</script>