import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";

export default class extends Controller {
  static targets = ['artist', 'shortlistedCount', 'toggleShortlistView', 'artistIds', 'submit'];

  static values = {
    artistIds: Array
  };

  connect() {
    this.updateActions();
    this.artistIdsValue = this.artistIdsValue.map((id) => parseInt(id));
    this.artistIdsValue.forEach((artistId) => this.addArtist(artistId));

    const shortlistDates = document.querySelector('.shortlist-dates');
    if (shortlistDates) {
      this._flatpicker = flatpickr(shortlistDates, {
        mode: "multiple",
        minDate: new Date(),
        dateFormat: "J M Y",
        plugins: [new confirmDatePlugin({ confirmText: "Done" })]
      });
    }

    this.toggleShortlistView();
  }

  toggleShortlist({ params }) {
    const artistId = parseInt(params.artist);
    if (this.artistIdsValue.includes(artistId)) {
      this.artistIdsValue = this.artistIdsValue.filter((id) => id !== artistId);
      this.removeArtist(artistId);
    } else {
      this.artistIdsValue = [
        ...this.artistIdsValue,
        artistId
      ];
      this.addArtist(artistId);
    }

    this.updateActions();
  }

  addArtist = (artistId) => {

    let artistTarget = this.artistTargets.find(artist =>  parseInt(artist.dataset.artistId) == parseInt(artistId));

    let shortlistedBadge = artistTarget.querySelector('.shortlisted-badge');
    if (shortlistedBadge) {
      shortlistedBadge.classList.remove('d-none');
    }

    let shortlistBtn = artistTarget.querySelector('.shortlist-btn');
    if (!shortlistBtn) {
      return;
    }

    let shortlistBtnText = shortlistBtn.querySelector('span');
    let shortlistBtnIcon = shortlistBtn.querySelector('i');

    shortlistBtn.classList.remove('btn-outline-primary');
    shortlistBtn.classList.add('btn-outline-success');

    shortlistBtnIcon.classList.remove('fa-plus');
    shortlistBtnIcon.classList.add('fa-check');

    shortlistBtnText.innerText = 'Added to Shortlist';
  }

  removeArtist = (artistId) => {
    let artistTarget = this.artistTargets.find(artist =>  parseInt(artist.dataset.artistId) == parseInt(artistId));
    let shortlistedBadge = artistTarget.querySelector('.shortlisted-badge');
    if (shortlistedBadge) {
      shortlistedBadge.classList.add('d-none');
    }

    let shortlistBtn = artistTarget.querySelector('.shortlist-btn');
    let shortlistBtnText = shortlistBtn.querySelector('span');
    let shortlistBtnIcon = shortlistBtn.querySelector('i');

    shortlistBtn.classList.remove('btn-outline-success');
    shortlistBtn.classList.add('btn-outline-primary');

    shortlistBtnIcon.classList.remove('fa-check');
    shortlistBtnIcon.classList.add('fa-plus');

    shortlistBtnText.innerText = 'Shortlist';
  }

  updateActions = () => {
    if (this.hasShortlistedCountTarget) {
      this.shortlistedCountTarget.innerText = this.artistIdsValue.length;
    }
    this.toggleShortlistViewTarget.disabled = this.artistIdsValue.length == 0;
    this.artistIdsTarget.value = this.artistIdsValue.join(',');
    if (this.hasSubmitTarget) {
      this.submitTarget.disabled = this.artistIdsValue.length == 0;
    }
  }

  toggleShortlistView = () => {
    console.log('toggleShortlistView', this.toggleShortlistViewTarget.checked);
    if (this.toggleShortlistViewTarget.checked) {
      this.artistTargets.forEach((artist) => {
        let artistId = parseInt(artist.dataset.artistId);
        if (!this.artistIdsValue.includes(artistId)) {
          artist.setAttribute('hidden', true)
        }
      });
    } else {
      this.artistTargets.forEach((artist) =>  artist.removeAttribute('hidden'));
    }
  }

}
