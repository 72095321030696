<template>
  <i :class="[iconClass, iconStyle]"></i>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "",
    },
    iconStyle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      iconClass: `fas fa-${this.icon}`,
    };
  },
  watch: {
    icon(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.iconClass = `fas fa-${newValue}`;
      }
    },
  },
};
</script>
