import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";
import { isEmpty } from "lodash";

export default class extends Controller {

  static targets = ['genericTab', 'specificTab', 'date', 'artist', 'booking', 'artists'];

  connect() {
    const params = new URLSearchParams(window.location.search)

    this._flatpicker = flatpickr(this.dateTarget, {
      mode: "multiple",
      minDate: params.get('date') ? null : new Date(),
      dateFormat: "J M Y",
      plugins: [new confirmDatePlugin({ confirmText: "Done" })]
    });

    if (params.get('date')) {
      this._flatpicker.setDate(new Date(params.get('date')));
    }

    if (!isEmpty(this.artistTarget.value)) {
      this.specificTabTarget.click();
      this.setGenericTabDisability(true)
    }

    $(this.artistsTarget).on('change.select2', this.handleArtistsChange)

  }

  disconnect() {
    this._flatpicker.destroy();
  }

  toggleBackdate = (e) => {
    this._flatpicker.set("minDate", e.target.checked ? undefined : new Date());
    if (!e.target.checked) {
      this._flatpicker.jumpToDate(new Date())
    }
    this._flatpicker.redraw();
  }


  handleArtistsChange = () => {
    if (!isEmpty($(this.artistsTarget).val())) {
      this.setGenericTabDisability(true)
    } else {
      this.setGenericTabDisability(false)
    }
  }

  setGenericTabDisability = (value) => {
    document.querySelectorAll("#generic-tab-pane select").forEach((select) => {
      $(select).prop('disabled', value).trigger('change');
    })
  }
}
