import axios from "axios";
import { format, fromUnixTime } from "date-fns";
import sortBy from "lodash/sortBy";

export const buildParams = (fields) => {
  const params = new URLSearchParams();
  Object.keys(fields).forEach((field) => {
    if (field == 'dates') {
      params.append(`search[${field}]`, fields[field].join(","));
    }
    else if (Array.isArray(fields[field])) {
      fields[field].forEach((value) => {
        params.append(`search[${field}][]`, value);
      });
    }
    else {
      params.append(`search[${field}]`, fields[field]);
    }
  });

  return params;
};

export const buildFilterParams = (filters = {}, page = null) => {
  const q = new URLSearchParams();
  Object.keys(filters).forEach((p) => q.append(`q[${p}]`, filters[p]));
  if (page) {
    q.append("page", page);
  }
  return q;
};

const api = axios.create({
  baseURL: "",
  headers: {
    "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
  },
});

export default api;

// Cloudinary Setup

const cloudinaryAccountName = document.querySelector(
  'meta[name="cloudinary-account-name"]'
).content;

const CLOUDINARY_API_KEY = document.querySelector(
  'meta[name="cloudinary-api-key"]'
).content;

export const CLOUDINARY_UPLOAD_URL = `https://api.cloudinary.com/v1_1/${cloudinaryAccountName}/upload`;

const typesPresets = {
  profile_photos: "model_portfolios",
  documents: "models_documents",
  profile_pic_employee: "model_portfolios",
};

export const buildFormData = (file, type) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("tags", type);
  formData.append("upload_preset", typesPresets[type] || "models_documents");
  formData.append("api_key", CLOUDINARY_API_KEY);
  formData.append("timestamp", (Date.now() / 1000) | 0);
  return formData;
};

const humanizeDate = (date) => {
  return format(fromUnixTime(date), "do MMM yyy");
};

const titleCase = (str) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

export class ArtistsTextGenerator {
  constructor(artists, dates) {
    this.artists = artists;
    this.dates = dates;
  }

  renderArtist(artist) {
    let str = titleCase(artist.nickname);
    if (artist.city) {
      str = `${str} (${artist.city})`;
    }

    if (artist.booking_dates && Object.keys(artist.booking_dates).length > 0) {
      str = str + "^";
    }

    if (
      this.withPortfolio ||
      this.withSnapshots ||
      this.withVideo ||
      this.withInstagram
    ) {
      str = str + `\n`;
    }

    if (this.withPortfolio && artist.portfolio) {
      str = str + `Portfolio: ${artist.portfolio} \n`;
    }

    if (this.withSnapshots && artist.snapshot) {
      str = str + `Introduction Video and Snapshots: ${artist.snapshot} \n`;
    }

    if (this.withVideo && artist.video) {
      str = str + `TVC: ${artist.video} \n`;
    }

    if (this.withInstagram && artist.instagram) {
      str = str + `Instagram: ${artist.instagram} \n`;
    }

    if (
      this.withPortfolio ||
      this.withSnapshots ||
      this.withVideo ||
      this.withInstagram
    ) {
      str = str + ``;
    }

    return str;
  }

  renderDates() {
    return this.dates.join(", ");
  }

  renderAllArtists() {
    let text = "";
    Object.keys(this.artists).forEach((artistCategory) => {
      text = `${text}
${artistCategory}\n`;

      const sortedArtists = sortBy(
        this.artists[artistCategory],
        "gender",
        "nickname"
      );
      sortedArtists.forEach((artist) => {
        const generatedText = this.renderArtist(artist);
        text = `${text}${generatedText}${
          this.withPortfolio ||
          this.withSnapshots ||
          this.withVideo ||
          this.withInstagram
            ? "\n"
            : ", "
        }`;
      });
    });
    return text;
  }

  render(withPortfolio, withSnapshots, withVideo, withInstagram) {
    this.withPortfolio = withPortfolio;
    this.withSnapshots = withSnapshots;
    this.withVideo = withVideo;
    this.withInstagram = withInstagram;
    const headerText = "Please find below the list of available artists, models and talent for your upcoming shoot."
    const footerText = `The caret (^) denotes the resource has an existing option on your prospective date.
    Please let us know in case you need any additional content or information.`

    if (this.dates.length > 0) {
      return `
${headerText}
Requested Dates
${this.renderDates()}
${this.renderAllArtists()}
${footerText}
      `;
    } else {
      return `
${headerText}
${this.renderAllArtists()}
${footerText}
      `;
    }
  }
}
