<template>
  <RemoteSelect
    name="customer"
    label="Customer"
    optionValue="id"
    optionLabel="company_name"
    :multiple="false"
    :filterable="false"
    :closeOnSelect="true"
    v-bind="$attrs"
    @handleSearch="handleFetchCustomers"
    @input="$emit('input', $event || {})"
  />
</template>
<script>
import debounce from "lodash/debounce";
import axios from "axios";

export default {
  methods: {
    handleFetchCustomers: debounce(function (search) {
      if (search.length >= 2) {
        axios
          .get(`/api/customers?q[company_name_start]=${search}`)
          .then((resp) => {
            const customers = resp.data.data.map((customer) => ({
              id: customer.id,
              contacts: customer.contacts,
              company_name: customer.company_name,
              notes: customer.notes,
            }));
            this.$emit("update", customers);
          });
      }
    }, 150),
  },
};
</script>
