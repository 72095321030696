<template>
  <div class="my-2 w-100">
    <label class="position-block me-1 form-label">
      {{ label }}
    </label>
    <v-select
      :value="value"
      :options="options"
      :disabled="disabled"
      :label="optionLabel"
      :multiple="multiple"
      :filterable="filterable"
      :clearable="clearable"
      :closeOnSelect="closeOnSelect"
      @input="$emit('input', $event)"
      @search="$emit('handleSearch', $event)"
    >
      <template slot="no-options">
        {{ `Start typing to search a ${label}..` }}
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Object, Array],
    label: String,
    options: Array,
    filterable: {
      type: Boolean,
      default: true,
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    name: String,
    multiple: {
      type: Boolean,
      default: true,
    },
    optionLabel: {
      type: String,
      default: "label",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
