import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['row', 'body'];

  connect() {
    console.log('nested form connected');
  }

  add(e) {
    e.preventDefault();
    let newRow = this.rowTarget.cloneNode(true)

    newRow.querySelectorAll("input").forEach(element => {
      element.name = element.name.replace(/[0-9]/, this.rowTargets.length)
      element.value = ""
    });

    this.bodyTarget.appendChild(newRow);
  }
}
