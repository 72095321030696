import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs";

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      draggable: "[data-sortable-id]",
      // handle: "[data-sortable-target='handle']",
      dataIdAttr: "data-sortable-id",
      animation: 150,
      onUpdate: this.save
    });
  }

  save = () => {
    const items = this.sortable.toArray();
    items.forEach((item, index) => {
      const priorityEl = document.querySelector(`[data-sortable-id='${item}'] .priority`);
      console.log('priority changed', priorityEl, item, index);
      priorityEl.value = index;
    });
  }
}
