<template>
  <div class="w-100">
    <label class="form-label">{{ label }} </label>
    <v-select
      :multiple="multiple"
      :options="options"
      :label="optionLabel"
      :clearable="clearable"
      :value="value"
      :closeOnSelect="closeOnSelect"
      :disabled="disabled"
      @input="$emit('input', $event)"
      v-bind="$attrs"
    />
    <div v-if="$page.props.errors && $page.props.errors[name]">
      <span
        v-for="error in $page.props.errors[name]"
        :key="label + error"
        class="invalid-feedback"
      >
        {{ label }} {{ error }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Object, Array, Number],
    label: String,
    placeholder: String,
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    name: String,
    multiple: {
      type: Boolean,
      default: true,
    },
    options: Array,
    optionLabel: {
      type: String,
      default: "label",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
