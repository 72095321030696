import { Controller } from "@hotwired/stimulus"
import { debounce } from "lodash";

export default class extends Controller {

  static targets = ["suggestion"]

  search(e) {

    const query = debounce(() => {
      fetch(`/api/customers?q[company_name_cont]=${e.target.value}`)
        .then(response => response.json())
        .then(({ data, records }) => {
          if (records > 0) {
            this.suggestionTarget.querySelector(`ul`).innerHTML = data.map(customer => `<li>${customer.company_name}</li>` ).join('')
            this.showSuggestions();
          } else {
            this.hideSuggestions();
          }
        });
    })

    // Let the DOM changes sink in
    setTimeout(() => {
      if (e.target.value.length > 1) {
        query();
      } else {
        this.hideSuggestions();
      }
    });

  }

  hideSuggestions() {
    this.suggestionTarget.classList.add('d-none')
    this.suggestionTarget.classList.remove('d-flex')
  }

  showSuggestions() {
    this.suggestionTarget.classList.add('d-flex')
    this.suggestionTarget.classList.remove('d-none')
  }
}
