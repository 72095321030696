<template>
  <Card>
    <template #heading>
      <span>{{ artist.nickname }}'s Portfolio</span>
    </template>
    <template #extras>
      <div class="me-2">
        <label>Filter by Tags</label>
        <v-select :options="tag_options" v-model="filterTags" @input="handleFilterTags" tagging multiple :multiple-separator="','" />
      </div>
    </template>
    <template #actions>
      <div class="d-flex align-items-center">
        <div class="me-2">
          <input type="checkbox" v-model="selectAll" @change="handleSelectAll" id="selectAll" class="me-1" />
          <label for="selectAll">Select All</label>
        </div>
        <div class="btn-group">
          <Link :href="`/artists/${artist.id}/pictures/upload`" class="btn btn-warning">Bulk Upload</Link>
          <button class="btn btn-danger" @click="handleDelete" :disabled="selectedPictures.length === 0">Delete</button>
          <button class="btn btn-primary" @click="handleSave" :disabled="pictures.length === 0">Save</button>
        </div>
      </div>
    </template>

    <div v-if="pictures.length > 0">
      <draggable v-model="pictures" group="pictures" @change="handlePriorityChange" class="d-flex flex-wrap flex-row">
        <PicturePortfolio :picture="picture" v-for="picture in pictures" :key="picture.id" :tag_options="tag_options" @selectToggle="handleSelectToggle" />
      </draggable>
    </div>
    <div class="text-center p-4" v-else>
      <strong>No Content in the Portfolio</strong>
    </div>
  </Card>
</template>
<script>
import { Link } from '@inertiajs/vue2'
import { router } from "@inertiajs/vue2"
import PicturePortfolio from "@/Components/Portfolios/Picture.vue";
import draggable from 'vuedraggable'

export default {
  components: {
    PicturePortfolio,
    draggable,
    Link,
  },
  props: {
    tag_options: {
      type: Array,
      required: true,
    },
    artist: {
      type: Object,
      required: true,
    },
    pictures: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectAll: false,
      filterTags: [],
    };
  },
  computed: {
    selectedPictures() {
      return this.pictures.filter((p) => p.selected);
    },
  },
  methods: {
    handleFilterTags() {
      router.visit(`/artists/${this.artist.id}/pictures?tags=${this.filterTags.join(',')}`, {
        only: ['pictures'],
        preserveState: true,
        preserveScroll: true,
      });
    },
    handlePriorityChange(e) {
      let ctr = 0
      this.pictures = this.pictures.map((picture) => ({ ...picture, priority: ++ctr }));
    },
    handleSave() {
      const pictures = this.pictures.map((picture) => ({ id: picture.id, priority: picture.priority, tag_list: picture.tag_list }));
      router.put(`/artists/${this.artist.id}/pictures/update_all`, {
        pictures: pictures,
      });
    },
    handleSelectToggle(picture) {
      this.pictures = this.pictures.map((p) => {
        if (p.id === picture.id) {
          return { ...p, selected: !p.selected };
        }
        return p;
      });
    },
    handleSelectAll(e) {
      this.pictures = this.pictures.map((p) => ({ ...p, selected: e.target.checked }));
    },
    handleDelete() {
      const selectedPictures = this.selectedPictures;
      if (confirm(`Are you sure you want to delete ${selectedPictures.length} picture(s)?`)) {
        router.post(`/artists/${this.artist.id}/pictures/destroy_all`, {
          pictures: selectedPictures,
        });
      }
    },
  },
};
</script>