<template>
  <Card>
    <template #heading> Edit Booking #{{ booking.id }} </template>
    <Form :booking="form.booking" :masters="masters" @change="handleOnChange" />
    <ArtistCard
      v-if="form.booking.booking_dates"
      :data="form.booking.booking_dates"
    />

    <template #footer>
      <Button @click="handleSubmit">Save</Button>
    </template>
  </Card>
</template>

<script>
import Form from "@/Components/Booking/Form.vue";
import ArtistCard from "@/Components/Booking/ArtistCard.vue";
import { router } from '@inertiajs/vue2'

export default {
  props: ["booking", "path", "masters"],
  data() {
    return {
      form: {
        booking: {},
      },
    };
  },
  components: {
    Form,
    ArtistCard,
  },
  methods: {
    handleOnChange({ name, value }) {
      const { booking } = this.form;
      this.form = {
        booking: {
          ...booking,
          [name]: value,
        },
      };
    },
    handleSubmit() {
      router.put(this.path, this.form);
    },
  },
  created() {
    this.form = {
      booking: {
        ...this.booking,
        booking_dates: this.booking.booking_dates.map((bd) => ({
          ...bd,
          amount: bd.amount_cents / 100,
        })),
      },
    };
  },
};
</script>
