<template>
  <Card>
    <template v-slot:heading>
      <Header :dates="dates" />
    </template>

    <div class="p-2 border d-flex justify-content-between">
      <div class="d-inline-flex">
        <div class="px-2">
          <Checkbox
            v-model="options.portfolio"
            label="Portfolio?"
            name="portfolio"
          />
        </div>
        <div class="px-2">
          <Checkbox
            v-model="options.snapshot"
            label="Snapshot?"
            name="snapshot"
          />
        </div>

        <div class="px-2">
          <Checkbox v-model="options.video" label="Video?" name="video" />
        </div>
        <div class="px-2">
          <Checkbox
            v-model="options.instagram"
            label="Instagram?"
            name="instagram"
          />
        </div>

      </div>
      <Button @click="handleCopy" :secondary="copied">{{copied ? "Copied" : "Copy Content"}}</Button>
    </div>

    <TextArea
      name="content"
      label=""
      v-model="content"
      :readonly="true"
      :rows="20"
    />

    <Footer :count="artists.length">
      <Button @click="goBack">Back</Button>
    </Footer>
  </Card>
</template>
<script>
import Header from "@/Components/Search/Header.vue";
import Footer from "@/Components/Search/Footer.vue";
import { ArtistsTextGenerator } from "../../utils.js";
import groupBy from "lodash/groupBy";

export default {
  props: ["dates", "artists"],
  components: {
    Footer,
    Header,
  },
  data() {
    return {
      options: {
        portfolio: false,
        snapshot: false,
        video: false,
        instagram: false,
      },
      copied: false,
      content: "",
    };
  },
  watch: {
    options: {
      handler() {
        this.generateContent();
      },
      deep: true,
    },
  },
  methods: {
    goBack() {
      window.history.back();
    },
    generateContent() {
      const { portfolio, snapshot, video, instagram } = this.options;
      this.content = this._generator.render(
        portfolio,
        snapshot,
        video,
        instagram
      );
    },
    handleCopy() {
      const content = document.querySelector("#content");
      content.select();
      try {
        document.execCommand("copy");
        this.copied = true;
      } catch (err) {
        alert("copy failed");
      }
    },
  },
  mounted() {
    const artists = groupBy(this.artists, (a) => a.artist_master.name);
    this._generator = new ArtistsTextGenerator(artists, this.dates);
    this.generateContent();
  },
};
</script>
