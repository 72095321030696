import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  copy() {
    let copyContent = document.querySelector('#copyContent').innerText;
    copyContent = copyContent.replace(`{{PAGE_LINK}}`, window.location.href)
    const content = this.element.innerHTML;
    navigator.clipboard.writeText(copyContent)
    this.element.innerText = 'Copied!'
    setTimeout(() => this.element.innerHTML = content, 2000)
  }

}
