<template>
  <div class="p-1 card m-1 d-flex" :class="{ 'border border-2 border-danger': picture.selected }">
    <div @click="$emit('selectToggle', picture)">
      <img :src="picture.thumbnail_url" class="card-img-top" style="width: 333px; height: 500px; object-fit: cover;" v-if="picture.asset_type === 'image'" />
      <video class="card-img-top" style="max-width: 600px; max-height: 400px; width: 100%; height: auto;" controls v-else>
        <source :src="picture.asset_url" type="video/mp4" />
      </video>
    </div>

    <div>
      <label class="mt-2">Tags</label>
      <v-select v-model="picture.tag_list" :options="tag_options" taggable multiple />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    picture: {
      type: Object,
      required: true,
    },
    tag_options: {
      type: Array,
      required: true,
    },
  },
};
</script>