<template>
  <section>
    <Card>
      <template #heading>
        <Header :dates="dates" />
      </template>
      <CustomerSelect
        :options="masters.customers"
        v-model="form.customer"
        @update="handleUpdateOptions"
      />
      <div v-if="form.customer.notes" class="alert alert-warning show">
        <div class="alert-inner--text">
          <strong>Notes:</strong>
          <span>{{ form.customer.notes }}</span>
        </div>
      </div>
      <Select
        label="Contacts"
        name="contacts"
        optionLabel="name"
        :options="form.customer.contacts"
        v-model="form.contacts"
      />
      <Input
        label="Brand"
        name="brand"
        placeholder="Brand name"
        v-model="form.brand"
      />

      <div
        class="alert alert-info"
        v-if="form.customer.id"
      >
        <strong>Attention:</strong>
        <p v-if="outstanding.booking">
          {{ form.customer.company_name }} has
          {{ outstanding.booking.count }} unbilled confirmed bookings worth of
          <mark>{{ outstanding.booking.total }}</mark>
          <span v-if="outstanding.invoice && outstanding.invoice.count > 0">
            and {{ outstanding.invoice.count }} outstanding invoice(s) worth of
            <strong>{{ outstanding.invoice.total }}</strong>
          </span>
        </p>
      </div>

      <Footer :count="artists.length">
        <Button
          @click="handleModalClick"
          :secondary="true"
          :disabled="!customerId"
          >Add Contact</Button
        >
        <Button @click="goBack"> Back </Button>
        <Button @click="onSubmit"> Create Tentative Booking </Button>
      </Footer>
    </Card>
    <AddContactModal
      ref="contactModal"
      :customer_id="customerId"
      @close="handleClose"
    />
  </section>
</template>
<script>
import Header from "@/Components/Search/Header.vue";
import Footer from "@/Components/Search/Footer.vue";
import AddContactModal from "@/Components/AddContactModal.vue";
import CustomerSelect from "@/Components/CustomerSelect.vue";
import { router } from "@inertiajs/vue2";
import axios from "axios";

export default {
  props: ["dates", "artists", "path"],
  components: {
    Footer,
    Header,
    CustomerSelect,
    AddContactModal,
  },
  data() {
    return {
      form: {
        customer: {},
        contacts: [],
      },
      outstanding: {},
      masters: {
        customers: [],
      },
    };
  },
  computed: {
    customerId() {
      return this.form.customer.id;
    },
  },
  watch: {
    customerId() {
      // Reset previous customer's selected contacts
      this.form = {
        ...this.form,
        contacts: [],
      };

      if (this.customerId) {
        // Fetch outstanding balance of selected customer
        axios
          .get(`/api/customers/${this.customerId}/outstanding`)
          .then((resp) => {
            this.outstanding = { ...resp.data };
          });
      }
    },
  },
  methods: {
    handleModalClick() {
      this.$refs.contactModal.open();
    },
    handleClose(contact) {
      const { customer } = this.form;
      this.form = {
        ...this.form,
        customer: {
          ...customer,
          contacts: [...customer.contacts, contact].flat(),
        },
        contacts: [...this.form.contacts, contact].flat(), // Preselected the created contact
      };
    },
    handleUpdateOptions(customers) {
      this.masters = {
        ...this.masters,
        customers,
      };
    },
    goBack() {
      window.history.back();
    },
    onSubmit() {
      const { form } = this;
      const booking_dates = [];
      this.dates.forEach((d) => {
        this.artists.forEach((a) => {
          booking_dates.push({ on_date: d, artist_id: a });
        });
      });

      router
        .post(this.path, {
          booking: {
            customer_id: form.customer.id,
            contacts: form.contacts.map((c) => ({
              id: c.id,
            })),
            brand: form.brand,
            booking_dates,
          },
          return_params: window.location.search,
        }, {
          onError: (errors) => {
            alert("There was an error while creating booking");
            console.error(errors);
          }
        });
    },
  },
};
</script>
