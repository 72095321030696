<template>
  <div class="form-check">
    <input
      :checked="value"
      :type="type"
      :name="name"
      :id="name"
      :value="value"
      @input="$emit('input', $event.target.checked)"
      class="form-check-input"
    />
    <label class="form-check-label" :for="name" :if="label !== null">
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "checkbox"
    },
    label: {
      type: String,
      default: null,
    },
    value: Boolean,
    name: String
  }
};
</script>
