<template>
  <div
    class="modal fade"
    id="addContactModal"
    tabindex="-1"
    aria-labelledby="addContactModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addContactModalLabel">Add Contact</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="handleSubmit">
            <div class="form-group">
              <label for="name" class="col-form-label">Name:</label>
              <input
                type="text"
                class="form-control"
                id="name"
                required
                v-model="form.name"
              />
            </div>
            <div class="form-group">
              <label for="contactno" class="col-form-label">Contact No:</label>
              <input
                type="text"
                class="form-control"
                id="contactno"
                v-model="form.contactno"
              />
            </div>
            <div class="form-group">
              <label for="email" class="col-form-label">Email:</label>
              <input
                type="text"
                class="form-control"
                id="email"
                required
                v-model="form.email"
              />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Close
          </button>
          <button @click.prevent="handleSubmit" class="btn btn-primary">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const formState = {
  name: null,
  contactno: null,
  email: null,
};
import axios from "axios";
export default {
  props: ["customer_id"],
  data() {
    return {
      loading: false,
      form: { ...formState },
      contact: null,
    };
  },
  methods: {
    handleSubmit() {
      this.loading = true;

      axios
        .post(`/api/contacts`, {
          contact: {
            ...this.form,
            customer_id: this.customer_id,
          },
        })
        .then((resp) => {
          this.contact = resp.data;
          this.close();
        })
        .finally(() => (this.loading = false));
    },
    open() {
      this.$nextTick(() => {
        $("#addContactModal").modal("show");
      });
    },
    close() {
      this.$nextTick(() => {
        $("#addContactModal").modal("hide");
      });
    },
  },
  mounted() {
    $("#addContactModal").on("hide.bs.modal", () => {
      if (this.contact) {
        this.$emit("close", this.contact);
      }

      this.contact = null;
      this.form = { ...formState };
    });
  },
};
</script>

<style></style>
