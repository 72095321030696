<template>
  <Card>
    <template #heading>
      <span>{{ artist.nickname }}'s Portfolio Bulk Upload</span>
    </template>

    <section class="d-flex flex-wrap flex-column">
      <Dashboard :uppy="uppy" :props="dashboard_options" width='100%' />
      <div class="form-group mt-4">
        <label for="tags">Tags To Assign:</label>
        <v-select taggable multiple v-model="form.tags" :options="tag_options" />
      </div>

      <div class="d-flex justify-content-center">
        <button class="btn btn-primary btn-lg" @click="handleUpload" :disabled="!allowUpload">{{ actionButtonText }}</button>
      </div>
    </section>
  </Card>
</template>
<script>
import { router } from "@inertiajs/vue2"
import { Dashboard } from '@uppy/vue';
import XHRUpload from '@uppy/xhr-upload'
import Uppy from '@uppy/core';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

export default {
  components: {
    Dashboard,
  },
  props: {
    artist: {
      type: Object,
      required: true,
    },
    tag_options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      form: {
        tags: [],
        uploadedFiles: [],
      },
      actionButtonText: "Upload",
      allowAction: true,
      dashboard_options: {
        inline: true,
        width: '100%',
        proudlyDisplayPoweredByUppy: false,
        note: 'Maximum upload limit is 20mb',
        hideUploadButton: true,
        doneButtonHandler: undefined,
        showRemoveButtonAfterComplete: false
      }
    };
  },
  computed: {
    uppy: () => {
      const uppy = new Uppy({
        restrictions: {
          maxFileSize: 1024 * 1024 * 20,
          allowedFileTypes: ['image/*', 'video/*'],
        },
      });
      uppy.use(XHRUpload, {
        endpoint: '/pictures/upload',
      });
      return uppy;
    },
    allowUpload() {
      return this.form.tags.length > 0 && this.uppy.getFiles().length > 0 && this.allowAction;
    },
  },
  methods: {
    handleUpload() {
      this.uppy.upload();
      this.actionButtonText = "Uploading...";
      this.allowAction = false;
    },
  },
  mounted() {
    this.uppy.on('upload-success', (file, response) => {
      this.form.uploadedFiles.push({
        file,
        response,
      });
    });

    this.uppy.on('complete', () => {
      this.actionButtonText = "Saving...";
      const { uploadedFiles, tags } = this.form;
      const pictures = uploadedFiles.map(file => ({
        picture: JSON.stringify(file.response.body),
        tag_list: tags,
      }));
      router.post(`/artists/${this.artist.id}/pictures/bulk_upload`, { pictures });
    });
  },
};
</script>

