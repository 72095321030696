<template>
  <div class="d-flex flex-column px-2">
    <div class="d-flex">
      <img class="img-thumbnail cursor-pointer avatar-big" :src="artist.profile_picture_url" @click="$emit('click')" />

      <div class="d-flex px-2 flex-column justify-content-center">
        <p class="mb-1 cursor-pointer" @click="$emit('click')">
          <strong>{{ artist.nickname }}</strong>
          <mark v-if="artist.city">({{ artist.city }})</mark>
        </p>
        <p v-if="artist.notes" class="small p-1">
          {{ artist.notes }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["artist"],
};
</script>
