<template>
  <div class="my-2 w-100">
    <label class="form-label" :for="name">
      {{ label | titleize }}
    </label>
    <input
      class="w-100 form-control"
      :type="type"
      :id="name"
      :value="value"
      :name="name"
      :placeholder="placeholder || label"
      @input="$emit('input', $event.target.value)"
    />
    <div v-if="$page.props.errors && $page.props.errors[name]">
      <span
        v-for="error in $page.props.errors[name]"
        :key="label + error"
        class="invalid-feedback"
      >
        {{ label }} {{ error }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "text",
    },
    name: String,
    label: String,
    value: String,
    placeholder: {
      type: String,
      default: null,
    },
  },
};
</script>
