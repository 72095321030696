<template>
  <form class="container-fluid">
    <div class="row gap-3">
      <div class="col">
        <CustomerSelect
          :options="customers"
          :value="booking.customer"
          :clearable="false"
          @input="handleCustomerChange"
          @update="handleUpdateOptions"
        />
      </div>
      <div class="col d-flex">
        <Select
          name="contacts"
          label="Contacts"
          optionLabel="name"
          :multiple="true"
          :options="contacts"
          v-model="booking.contacts"
        />
        <div class="d-flex mx-2 align-items-end justify-content-center">
          <button type="button" class="btn btn-sm btn-secondary" @click.prevent="handleModalClick">
            <Icon icon="user-plus" />
          </button>
        </div>
      </div>
    </div>
    <div class="row gap-3">
      <div class="col">
        <Input
          name="type_of_engagement"
          label="Type of engagement"
          v-model="booking.type_of_engagement"
        />
      </div>
    <div class="col">
      <Input
        name="exclusivity_period"
        label="Exclusivity period"
        v-model="booking.exclusivity_period"
      />
      </div>
    </div>
    <div class="row gap-3">
      <div class="col">
        <Input name="brand" label="Brand" v-model="booking.brand" />
      </div>
      <div class="col">
        <Select
        name="shooting_medium"
        label="Nature of Assignment"
        :options="masters.shooting_medium"
        :clearable="false"
        :multiple="false"
        :closeOnSelect="true"
        v-model="booking.shooting_medium"
      />
      </div>
    </div>
    <div class="row gap-3">
      <div class="col">
        <Input name="category" label="Category" v-model="booking.category" />
      </div>
      <div class="col">
        <Input
        name="release_territory"
        label="Release territory"
        v-model="booking.release_territory"
      />
      </div>
    </div>
    <div class="row gap-3">
      <div class="col">
      <Select
        label="Release media"
        name="release_media"
        :options="masters.release_media"
        v-model="booking.release_media"
      />
      </div>
      <div class="col">
        <Input
        name="release_period"
        label="Release period"
        v-model="booking.release_period"
      />
      </div>
    </div>
    <div class="row gap-3">
      <div class="col">
        <Input
          label="No. of shooting days"
          name="no_of_shooting_days"
          v-model="booking.no_of_shooting_days"
        />
      </div>
      <div class="col">
        <Input
        label="No. of social media post"
        name="no_of_social_media_post"
        v-model="booking.no_of_social_media_post"
      />
      </div>
    </div>
    <div class="row gap-3">
      <div class="col">
        <Select
          name="payment_due_in"
          label="Payment Due In"
          placeholder="should expect payment in"
          v-model="payment_due_in"
          optionLabel="name"
          :options="masters.payment_due_in"
          :multiple="false"
          @input="handlePaymentDueChange"
          :closeOnSelect="true"
        />
      </div>
      <div class="col">
        <Select
        name="managers"
        label="Managers"
        optionLabel="username"
        :multiple="true"
        :options="masters.users"
        v-model="booking.managers"
      />
      </div>
    </div>
    <TextArea name="notes" label="Notes" v-model="booking.notes" />
    <AddContactModal
      ref="contactModal"
      :customer_id="booking.customer_id"
      @close="handleClose"
    />
  </form>
</template>

<script>
import CustomerSelect from "@/Components/CustomerSelect.vue";
import Icon from "@/Components/Icon.vue";
import AddContactModal from "@/Components/AddContactModal.vue";
export default {
  props: ["booking", "masters"],
  components: {
    CustomerSelect,
    Icon,
    AddContactModal,
  },
  data() {
    return {
      payment_due_in: {},
      customers: [],
      contacts: [],
    };
  },
  methods: {
    handleModalClick() {
      this.$refs.contactModal.open();
    },
    handleClose(contact) {
      this.contacts = [...this.contacts, contact].flat();
      this.handleSelectChange({
        name: "contacts",
        value: [...this.booking.contacts, contact],
      });
    },
    handleUpdateOptions(customers) {
      this.customers = customers;
    },
    handleCustomerChange(event) {
      const { contacts, ...customer } = event;
      this.contacts = contacts;
      this.handleSelectChange({ name: "customer", value: customer });
      this.handleSelectChange({ name: "customer_id", value: customer.id });
      this.handleSelectChange({ name: "contacts", value: [] });
    },
    handlePaymentDueChange(payload) {
      let value = null;
      if (payload) {
        value = payload.value;
      }
      console.log("payment changed", value);
      this.handleSelectChange({ name: "payment_due_in", value });
    },
    handleSelectChange({ name, value }) {
      this.$emit("change", { name, value });
    },
  },
  mounted() {
    this.contacts = this.masters.contacts;
    this.payment_due_in = this.masters.payment_due_in.find(
      (p) => p.value == this.booking.payment_due_in
    );
  },
};
</script>
