<template>
  <div>
    <label class="form-label">{{ label }} </label>
    <input
      class="form-control"
      placeholder="Please Select A Date"
      ref="datePickerInput"
    />
    <p v-if="$page.props.errors && $page.props.errors[name]">
      <span
        v-for="error in $page.props.errors[name]"
        :key="label + error"
        class=""
      >
        {{ label }} {{ error }}
      </span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    label: String,
    error: String,
    allowPrevDates: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Date, Array],
    },
    mode: {
      type: String,
      default: "single",
    },
  },
  data() {
    return {
      config: {
        mode: this.mode,
        minDate: new Date(),
        defaultDate: this.value,
        dateFormat: "j M Y",
        plugins: [new confirmDatePlugin({ confirmText: "Done" })],
      },
    };
  },
  watch: {
    allowPrevDates(newValue) {
      this.$nextTick(() => {
        this._flatpickr.set("minDate", newValue ? null : new Date());
      });
    },
    value(newValue) {
      // Below condition prevents an odd bug with flatpickr
      if (this._flatpickr && newValue !== this._flatpickr.selectedDates) {
        this._flatpickr.setDate(newValue);
      }
    },
  },
  methods: {
    handleOnClose(dates) {
      this.$emit("input", this.mode === "single" ? dates[0] : dates);
    },
  },
  created() {
    const config = this.config;
    this.$nextTick(() => {
      this._flatpickr = flatpickr(this.$refs.datePickerInput, {
        ...config,
        minDate: this.allowPrevDates ? null : new Date(),
        onClose: this.handleOnClose,
      });
    });
  },
};
</script>
