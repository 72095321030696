import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";

export default class extends Controller {
  connect() {
    let value;

    if (this.element.value) {
      value = new Date(this.element.value);
    }

    this._picker = new flatpickr(this.element, {
      dateFormat: "J M Y",
      plugins: [new confirmDatePlugin({ confirmText: "Done" })],
      defaultDate: value,
    })
  }

  disconnect() {
    this._picker.destroy()
  }
}
