import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "image", "file", "button", "url", "thumb"]


  openFile(e) {
    e.preventDefault();
    this.fileTarget.click();
  }

  upload = () => {
    const file = this.fileTarget.files[0];
    const cloudinaryApiKey = document.querySelector('meta[name="cloudinary-api-key"]').content;
    const cloudinaryAccountName = document.querySelector('meta[name="cloudinary-account-name"]').content;
    const uploadUrl = `https://api.cloudinary.com/v1_1/${cloudinaryAccountName}/image/upload`;

    console.log('file', file);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("tags", "profile_pic_employee");
    formData.append("upload_preset", "model_portfolios");
    formData.append("api_key", cloudinaryApiKey);
    formData.append("timestamp", (Date.now() / 1000) | 0);

    this.buttonTarget.classList.add("is-loading");
    this.buttonTarget.disabled = true;
    this.buttonTarget.innerHTML = "Uploading...";

    fetch(uploadUrl, {
      method: "POST",
      body: formData,
    })
    .then(r => r.json())
    .then((response) => {
      this.buttonTarget.classList.remove("is-loading");
      this.buttonTarget.disabled = false;
      this.buttonTarget.innerHTML = "Change Profile Picture";
      this.urlTarget.value = response.secure_url;
      this.thumbTarget.value = response.secure_url;
      this.imageTarget.src = response.secure_url;
    });

  }
}
