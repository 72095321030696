<template>
  <section class="container-fluid">
    <div class="py-2 row">
      <div class="col-12">
        <div class="py-2 border border-start-0 border-end-0 d-flex align-items-center">
          <Checkbox @input="$emit('selectGroup', { checked: $event, group })" :value="isGroupSelected" />
          <h4 class="cursor-pointer" @click="$emit('selectGroup', { checked: $event, group })">{{ group.name }}</h4>
        </div>
      </div>
    </div>
    <ArtistListItem v-for="artist in sortedArtists()" :key="'artist-' + artist.nickname" :artist="artist" @select="$emit('selectArtist', $event)" :showPortfolio="showPortfolio" />
  </section>
</template>
<script>
import ArtistListItem from "@/Components/Search/ArtistListItem.vue";
import sortBy from "lodash/sortBy";

export default {
  components: {
    ArtistListItem,
  },
  props: ["group", "artists", "showPortfolio"],
  computed: {
    isGroupSelected() {
      const selectedArtistsCount = this.artists.filter((a) => a.selected)
        .length;
      return selectedArtistsCount === this.artists.length;
    },
  },
  methods: {
    sortedArtists() {
      return sortBy(this.artists, ["gender", "nickname"]);
    },
  },
};
</script>
