<template>
  <div>
    <label class="form-label" :for="name">
      {{ label }} <em>{{ note }}</em>
    </label>
    <textarea
      class="form-control"
      :id="name"
      :rows="rows"
      :readonly="readonly"
      :value="value"
      :name="name"
      :placeholder="placeholder"
      @input="$emit('input', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  props: {
    rows: {
      type: Number,
      default: 3,
    },
    note: String,
    label: String,
    value: String,
    placeholder: String,
    readonly: Boolean,
    name: String,
  },
};
</script>
