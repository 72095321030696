<template>
  <div class="container-fluid">
    <div class="py-2 my-2 row" v-for="artist in artists" :key="artist.nickname">
      <div class="col">
        <img
          :src="artist.profile_picture_thumb_url"
          :alt="artist.nickname"
          class="avatar img-thumbnail"
        />
        <a :href="'/artists/' + artist.id + '/schedule'" target="_blank" class="ms-2">{{ artist.nickname }}</a>
      </div>

      <div class="mb-2 row bg-secondary">
        <div class="col">
          <strong class="text-white">Date</strong>
        </div>
        <div class="col">
          <strong class="text-white">Artist Commission</strong>
        </div>
        <div class="col">
          <strong class="text-white">Amount</strong>
        </div>
      </div>

      <div class="py-2 row" v-for="booking in artistBookings[artist.id]" :key="artist.nickname + booking.on_date">
        <div class="col">
          <mark class="p-2">{{ booking.on_date | toDate | humanizeDate }}</mark>
        </div>
        <div class="col input-group">
          <input
            type="text"
            class="form-control"
            v-model.number="booking.artist_commission_percentage"
          />
          <span class="input-group-text">%</span>
        </div>
        <div class="col">
          <Cleave
            class="form-control"
            v-model="booking.amount"
            :options="amountInputOpts"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cleave from "vue-cleave-component";
import groupBy from "lodash/groupBy";
import uniqBy from "lodash/uniqBy";
import parseISO from "date-fns/parseISO";

export default {
  props: ["data"],
  components: {
    Cleave,
  },
  data() {
    return {
      amountInputOpts: window.__AMOUNT_INPUT_MASK__,
    };
  },
  computed: {
    artistBookings() {
      const data = this.data
        .filter((d) => d.status !== "cancelled")
        .sort((a, b) => parseISO(a.on_date) - parseISO(b.on_date));

      console.log("data", data);
      return groupBy(data, (d) => d.artist.id);
    },
    artists() {
      return uniqBy(
        this.data.map((d) => d.artist),
        (a) => a.id
      );
    },
  },
};
</script>
