<template>
  <button
    class="btn"
    :class="{
      'btn-primary': !secondary && !disabled,
      'btn-secondary': secondary && !disabled
    }"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
